import logo from './logo.svg';
import AppleMusic from "./assets/apple-music.svg"
import Spotify from "./assets/spotify-icon.svg"
import SoundCloud from "./assets/soundcloud.png"
import Evan from "./assets/evan.jpeg"
import Geneseo from "./assets/geneseo.png"
import './App.css';
import LinkedIcon from "./components/linked-icon"

function App() {
  return (
    <div className="App">
      <img src={Evan} className="profile-picture shadow"/>

      <h1>Listen on...</h1>
      <div className="horizontal-container">
        <LinkedIcon image={Spotify} url={"https://open.spotify.com/artist/1IOcahAdmq5FLJTyinX104"} name={"Spotify"}/>
        <LinkedIcon image={AppleMusic} url={"https://music.apple.com/us/artist/evan-panzer/1487872763"} name={"Apple Music"}/>
        <LinkedIcon image={SoundCloud} url={"https://soundcloud.com/evan-panzer"} name={"SoundCloud"}/>
      </div>
      <div className="card shadow">
        <h2>🎓 Class of 2022! 🎉</h2>
        <img src={Geneseo} className="logo"/>
      </div>
    </div>
  );
}

export default App;
